import * as yup from 'yup';

export const autoMatchDepositSchema = yup.object().shape({
  enabled: yup.boolean().required('Enabled is required.'),
  agentID: yup
    .string()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .min(3, 'Agent ID must be at least 3 characters.')
          .required('Agent ID is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
  matchPercentages: yup
    .array()
    .of(
      yup.object().shape({
        role: yup
          .string()
          .oneOf(
            ['manager', 'user', 'admin', 'super_admin'],
            'Role must be Manager, Agent, Admin or Super Admin.'
          )
          .required('Role is required.'),
        percentage: yup
          .number()
          .required('Percentage is required.')
          .moreThan(0, 'Percentage must be greater than 0.')
          .max(100, 'Percentage must be less than or equal to 100.'),
      })
    )
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .required('At least one match percentage must be defined.')
          .min(1, 'At least one match percentage must be defined.')
          .test('unique-roles', 'Roles must be unique.', (value) => {
            if (!value || value.length === 0) return true; // Skip validation if no roles
            const roles = value.map((item) => item.role);
            return new Set(roles).size === roles.length; // Ensure no duplicates
          }),
      otherwise: (schema) => schema.nullable(),
    }),
  minTransferAmount: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .moreThan(0, 'Minimum Transfer Amount must be greater than 0.')
          .required('Minimum Transfer Amount is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
  maxTransferAmount: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .moreThan(0, 'Maximum Transfer Amount must be greater than 0.')
          .required('Maximum Transfer Amount is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
  minDepositAmount: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .moreThan(0, 'Minimum Deposit Amount must be greater than 0.')
          .required('Minimum Deposit Amount is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
  startDay: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .min(0, 'Invalid start day.')
          .max(6, 'Invalid start day.')
          .required('Start day is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
  endDay: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .min(0, 'Invalid end day.')
          .max(6, 'Invalid end day.')
          .required('End day is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const roleOptions = [
  { value: 'manager', label: 'Manager' },
  { value: 'user', label: 'Agent' },
  { value: 'admin', label: 'Admin' },
  { value: 'super_admin', label: 'Super Admin' },
];
